<template>
  <div class="house flex justify-center">
    <div class="lg:w-2/3 px-4 max-w-screen-xl pt-8">
      <h1 class="font-voll text-3xl text-maroon text-center">
        Wohnen auf dem Allemannenhaus
      </h1>
      <carousel
        ref="car1"
        class="story-carousel story-carousel--colors py-4"
        hideArrows
      >
        <slide class="story-carousel__slide voll">
          <img
            loading="eager"
            src="@/assets/img/haus/wohnen3.jpg"
            srcset="
              @/assets/img/haus/wohnen3.jpg,
              @/assets/img/haus/wohnen3_800.jpg 800w,
              @/assets/img/haus/wohnen3_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Wohnen 3"
          />
        </slide>
        <slide class="story-carousel__slide">
          <img
            loading="lazy"
            src="@/assets/img/haus/haus5.jpg"
            srcset="
              @/assets/img/haus/haus5.jpg,
              @/assets/img/haus/haus5_800.jpg 800w,
              @/assets/img/haus/haus5_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Haus 5"
          />
        </slide>
        <slide class="story-carousel__slide">
          <img
            loading="lazy"
            src="@/assets/img/haus/wohnen1.jpg"
            srcset="
              @/assets/img/haus/wohnen1.jpg,
              @/assets/img/haus/wohnen1_800.jpg 800w,
              @/assets/img/haus/wohnen1_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Wohnen 1"
          />
        </slide>
        <slide class="story-carousel__slide">
          <img
            loading="lazy"
            src="@/assets/img/haus/wohnen4.jpg"
            srcset="
              @/assets/img/haus/wohnen4.jpg,
              @/assets/img/haus/wohnen4_800.jpg 800w,
              @/assets/img/haus/wohnen4_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Wohnen 4"
          />
        </slide>
      </carousel>
      <div>
        <IconBase
          @click="$refs.car1.changeSlide(-1)"
          class="hidden lg:inline mx-4 transform rotate-180"
          width="50"
          height="25"
        />
        <IconBase
          @click="$refs.car1.changeSlide(1)"
          class="hidden lg:inline mx-4"
          width="50"
          height="25"
        />
      </div>
      <div class="text-left lg:py-4">
        Das Allemannenhaus bietet eine Anzahl von Zimmern verschiedener Größe
        für Hausbewohner und jüngere Bundesbrüder. Die Zimmer sind mit einem
        modernen Beleuchtungskonzept, Echtholzböden und Ethernetanschlüssen
        ausgestattet und bereits möbliert. Die Wohnlage im Herzen der Altstadt
        und die studentenfreundlichen Mieten, in welchen bereits Internet, Strom
        und Wasser einbegriffen sind, bieten ideale Vorraussetzungen für eine
        sorgenfreie Studienzeit. Hausbewohnern ist die Nutzung aller Salons, des
        Gartens, des Waschkellers und sonstiger Einrichtungen auf dem Haus
        möglich. Das Allemannenhaus bietet flächendeckendes WLAN. Ein besonderes
        Kleinod ist der große, schattige Garten unter dem Schlossberg.
      </div>
      <h1 class="font-voll text-3xl text-maroon text-center pt-4">
        Studieren auf dem Allemannenhaus
      </h1>
      <carousel
        ref="car2"
        class="story-carousel story-carousel--colors py-4"
        hideArrows
      >
        <slide class="story-carousel__slide voll">
          <img
            loading="lazy"
            src="@/assets/img/haus/wohnen2.jpg"
            srcset="
              @/assets/img/haus/wohnen2.jpg,
              @/assets/img/haus/wohnen2_800.jpg 800w,
              @/assets/img/haus/wohnen2_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Wohnen 2"
          />
        </slide>
        <slide class="story-carousel__slide">
          <img
            loading="lazy"
            src="@/assets/img/haus/haus2.jpg"
            srcset="
              @/assets/img/haus/haus2.jpg,
              @/assets/img/haus/haus2_800.jpg 800w,
              @/assets/img/haus/haus2_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Haus 2"
          />
        </slide>
      </carousel>
      <div>
        <IconBase
          @click="$refs.car2.changeSlide(-1)"
          class="hidden lg:inline mx-4 transform rotate-180"
          width="50"
          height="25"
        />
        <IconBase
          @click="$refs.car2.changeSlide(1)"
          class="hidden lg:inline mx-4"
          width="50"
          height="25"
        />
      </div>
      <div class="text-left lg:py-4">
        Die Allemannia identifiziert sich als Teil der akademischen Gemeinschaft
        Heidelbergs. Sie hält ihre Mitglieder zur disziplinierten Ableistung
        ihres Studiums und zum engagierten Einsatz in extrakurrikularen
        Aktivitäten an der Universität und in der Stadt an. Dafür befinden sich
        auf dem Haus ein Lernstock mit individuellen Lernplätzen und Salons mit
        Lernmitteln wie Beamer und Whiteboard. Lerngruppen verschiedener
        Studienrichtungen, akademische Vorträge und Kollegien gehören zum
        alltäglichen Leben auf dem Allemannenhaus. Ein besonderer Bonus für
        Mitglieder ist die Gelegenheit, auf das Wissen und die Erfahrung bereits
        examinierter Mitglieder zurück greifen zu können.
      </div>
      <h1 class="font-voll text-3xl text-maroon text-center pt-4">
        Leben auf dem Allemannenhaus
      </h1>
      <carousel
        ref="car3"
        class="story-carousel story-carousel--colors py-4"
        hideArrows
      >
        <slide class="story-carousel__slide voll">
          <img
            loading="lazy"
            src="@/assets/img/haus/leben0.jpg"
            srcset="
              @/assets/img/haus/leben0.jpg,
              @/assets/img/haus/leben0_800.jpg 800w,
              @/assets/img/haus/leben0_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Leben 0"
          />
        </slide>
        <slide class="story-carousel__slide">
          <img
            loading="lazy"
            src="@/assets/img/haus/leben1.jpg"
            srcset="
              @/assets/img/haus/leben1.jpg,
              @/assets/img/haus/leben1_800.jpg 800w,
              @/assets/img/haus/leben1_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Leben 1"
          />
        </slide>
        <slide class="story-carousel__slide">
          <img
            loading="lazy"
            src="@/assets/img/haus/haus1.jpg"
            srcset="
              @/assets/img/haus/haus1.jpg,
              @/assets/img/haus/haus1_800.jpg 800w,
              @/assets/img/haus/haus1_400.jpg 400w
            "
            sizes=" (max-width: 1000px) 100vw, 75vw"
            alt="Haus 1"
          />
        </slide>
      </carousel>
      <div>
        <IconBase
          @click="$refs.car3.changeSlide(-1)"
          class="hidden lg:inline mx-4 transform rotate-180"
          width="50"
          height="25"
        />
        <IconBase
          @click="$refs.car3.changeSlide(1)"
          class="hidden lg:inline mx-4"
          width="50"
          height="25"
        />
      </div>
      <div class="text-left lg:py-4">
        "Nicht der Pflicht nur zu genügen" lautet es in einem bekannten
        Studentenlied und dies wird auf dem Allemannenhaus genauso gelebt. Wir
        veranstalten regelmäßig Bälle, Parties, Tanzkurse und Kneipen auf
        welchen wir mit unseren Kommilitonen und Freunden feiern, singen und
        tanzen. Neben den regelmäßigen, professionell organisierten Festivitäten
        finden intimere, spontane Veranstaltungen statt - wie in jeder anderen
        Studenten-WG. Als Mitglied hast Du die Gelegenheit, Eigeninitiative zu
        zeigen und Deine eigenen Ideen zu verwirklichen.
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import { Carousel, Slide } from "vue-snap";
import "vue-snap/dist/vue-snap.css";
import IconBase from "@/components/IconBase.vue";
export default {
  name: "ViewHouse",
  components: {
    Carousel,
    Slide,
    IconBase,
  },
  setup() {
    useMeta({
      title: "Haus",
      description: "Haus der Burschenschaft Allemannia",
    });
  },
};
</script>

<style>
.story-carousel .story-carousel__slide {
  font-family: "Vollkorn", serif;
  /*height: 400px;*/
  font-size: 28px;
  color: #800000;
}
.story-carousel--colors .story-carousel__slide:nth-child(n + 1) {
  background: rgba(255, 251, 235);
}
.story-carousel--colors .story-carousel__slide:nth-child(2n + 1) {
  background: rgba(255, 251, 235);
}
</style>
