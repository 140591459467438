<script>
import mitt from 'mitt'
const emitter = mitt()
export default {
  props: ["do"],
  mounted() {
    const listener = e => {
      if (e.target !== this.$el && !this.$el.contains(e.target)) {
        this.do();
      }
    };
    document.addEventListener("click", listener);
    emitter.emit("hook:destroyed", () => {
      document.removeEventListener("click", listener);
    });
  },
  render() {
    return this.$slots.default()[0];
  }
};
</script>
