<template>
<div ref="container" class="absolute w-full h-full overflow-auto overscroll-none justify-center bg-yellow-50 py-8">
    <div class="absolute flex w-full justify-center">
        <div ref="month_name" class="absolute justify-center font-voll text-maroon text-3xl text-center w-60">
            {{ monthName }}
        </div>
    </div>
    <div style="height: 36px;"></div>
    <div v-if=!this.$mobile ref="month_days" class="absolute w-full md:flex flex-wrap justify-around md:justify-items-start"> 
        <div ref="left_column" class="md:w-2/5">
            <ProgramDay  v-for="n in firstDays" :date=this.dates[n] :key="n"></ProgramDay>
        </div>
        <div ref="right_column" class="md:w-2/5 pb-1">
            <ProgramDay  v-for="n in remainingDays" :date=this.dates[n] :key="n"></ProgramDay>
        </div>
    </div>
    <div v-if=this.$mobile ref="month_days" class="absolute w-full md:flex flex-wrap justify-around md:justify-items-start"> 
        <div ref="left_column" class="w-full">
            <ProgramDay  v-for="n in this.month_days[this.month]" :date=this.dates[n] :key="n"></ProgramDay>
        </div>
    </div>
</div>
</template>

<script>
import ProgramDay from "./ProgramDay";
import emitter from '@/eventBus';
// const zeroPad = (num, places) => String(num).padStart(places, '0')
const month_names = ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"];
export default {
  name: "ProgramMonth",
      data() {
        var month_days = [31,28,31,30,31,30,31,31,30,31,30,31];
        var dates = [];
        for (let i = 0; i <= month_days[this.month]; i++){
            dates.push(new Date(this.year, this.month, i));
        }

        return {month_days:month_days,
                dates:dates};
    },
    props:["month","year"],
    components: {
        ProgramDay
  },
  computed: {
    firstDays: function () {
        if (this.month_days[this.month]<= 30){
         return 15;
        }
        return 16;
    },
    remainingDays: function () {
        if (this.month_days[this.month]<= 30){
         return this.range(16,this.month_days[this.month]);
        }
        return this.range(17,this.month_days[this.month]);
    },
    monthName:function(){
        return month_names[this.month] + " " + this.year;
    }
  },
  methods:{
    range: function(min,max){
        var array = [],
        j = 0;
        for(var i = min; i <= max; i++){
            array[j] = i;
            j++;
        }
        return array;
    },
    previousMonth: function(){
        if (this.currentMonthIndex != 0){
        this.animateCurrent(this.container_w);
        this.currentMonthIndex = this.currentMonthIndex - 1;
        }
    },
    nextMonth: function(){
        if (this.currentMonthIndex != 5){
        this.animateNext(0);
        this.currentMonthIndex = this.currentMonthIndex + 1;
        }
    }
  },
  mounted () {
    this.$nextTick(() => {
        if (this.$refs.container) {
            // var childPos = this.$refs.month_name.offset();
            // var parentPos = this.$refs.month_name.parent().offset();
            emitter.emit('month_geometry',
                {height:this.$refs.month_name.getBoundingClientRect().height+this.$refs.month_days.getBoundingClientRect().height,
                 width:window.innerWidth,
                 name_height:this.$refs.month_name.getBoundingClientRect().height});
        }
    });
  }
};
</script>
