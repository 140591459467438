<template>
 <div ref="container" :style="{height:month_height + 'px'}" class="relative h-96 w-full select-none font-voll">
      <div class="absolute flex justify-center w-full pt-8">
          <IconBase @click="previousMonth" class="transform rotate-180 z-60" width="50" height="36"/>
          <div class="w-60"></div>
          <IconBase @click="nextMonth" class="z-60" width="50" height="36"/>
      </div>  
      <div class="relative w-4/5 max-w-screen-2xl h-full inset-x-1/2 transform -translate-x-1/2 overflow-hidden justify-center">  
            
        <ProgramMonth v-for="monthData in listMonthData" :key="monthData.month"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        ref="month" :class="monthData.zValue" 
        :style="{left:monthData.xs +'px'}" :month="monthData.month" :year="monthData.year"></ProgramMonth>
    </div>
 </div>
</template>

<script>
import ProgramMonth from "@/components/ProgramMonth"
import { gsap } from 'gsap'
import emitter from '@/eventBus'
import IconBase from '@/components/IconBase.vue'
//import IconBase from '@/components/IconBase.vue'

export default {
  name: "ProgramMain",
  data(){
    // choose months to be displayed according to semester
    var months = [];
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth();
    var minMonth = 2; // March
    var maxMonth = 7; // September
    // currentMonth in Mar-Jul ? SS (Mar-Aug) : WS (Aug-Mar)
    if (currentMonth < 2 | currentMonth >= 7 ){
      minMonth = 7; // September
      maxMonth = 14; // March
    }
    for (let i = minMonth; i<=maxMonth; ++i) {
      months.push(i%12);
    }
    return {
        currentMonthIndex: months.indexOf(currentMonth),
        current_x: 0,
        months_height:0,
        month_xs:Array(months.length).fill(0),
        container_h:0,
        months:months,
        container_w:0,
        month_name:{
          x:0,
          y:0
        },
        drag_data: {
          diff:0,
          start_x: 0,
          start_y: 0,
          current_x: 0,
          current_y: 0,
          dragging: false,
          mouse_down: false,
          animation:{
          }
      },
      prog_dict:{}
    };
  },
  components: {
    ProgramMonth,IconBase
  },
  methods: {
    handleMouseDown:function(event){
      this.drag_data.start_x = event.clientX;
      this.drag_data.start_y = event.clientY;
      this.drag_data.mouse_down = true;
    },
    handleTouchStart:function(event){
      this.drag_data.start_x = event.touches[0].clientX;
      this.drag_data.start_y = event.touches[0].clientY;
      this.drag_data.mouse_down = true;
      // event.preventDefault();  
    },
    handleScroll: function(event){
      this.drag_data.mouse_down = false;
      if (!this.drag_data.dragging){
        return;
      }
        //swipe right
        event.stopPropagation();
        if (this.diff > 0){
          if (this.diff <= this.container_w/2){
            //not far enough, go back
            this.animateCurrent(0);
          } else {
            //far enough, complete movement
            if (this.currentMonthIndex != 0){
              this.animateCurrent(this.container_w);
              this.currentMonthIndex = this.currentMonthIndex - 1;
            } else {
              this.animateCurrent(0);
            }
          }
        //swipe left
        } else {
          //not far enough, go back
          if (this.diff >= - this.container_w/2){
            if (this.currentMonthIndex < this.months.length){
              this.animateNext(this.container_w);
            }
          } else {
            //far enough,complete movement
            if (this.currentMonthIndex < this.months.length){
              this.animateNext(0);
              this.currentMonthIndex = this.currentMonthIndex + 1;
            }
        
          }
        }
        this.drag_data.dragging = false;
    },
    handleMouseMove: function(event){
      if (this.drag_data.mouse_down){
        this.drag_data.dragging = true;
      }
      else {
        return;
      }
        if (this.drag_data.dragging){
          this.drag_data.current_x = event.clientX;
          this.drag_data.current_y = event.clientY;
          this.diff = this.drag_data.current_x - this.drag_data.start_x;
          if (this.diff > 0){
            this.month_xs[this.currentMonthIndex] = this.diff;
          } else {
            if (this.currentMonthIndex < this.months.length){
              this.month_xs[this.currentMonthIndex+1] = this.container_w + this.diff
            }
          }
        }
      },
    handleTouchMove: function(event){
      var horidrag = true;
      if (this.drag_data.mouse_down && horidrag){
        this.drag_data.dragging = true;
      }
      else {
        return;
      }
        if (this.drag_data.dragging){
          this.drag_data.current_x = event.touches[0].clientX;
          this.drag_data.current_y = event.touches[0].clientY;
          this.diff = this.drag_data.current_x - this.drag_data.start_x;
          if (this.diff > 0){
            this.month_xs[this.currentMonthIndex] =  this.diff;
          } else {
            if (this.currentMonthIndex < this.months.length){
              this.month_xs[this.currentMonthIndex+1] = this.container_w + this.diff
            }
          }
        }
      // event.preventDefault();
      },
    animateCurrent: function(target){
      gsap.to(this.month_xs, {[this.currentMonthIndex]:target,duration:0.4});
    },
    animateNext: function(target){
      var nextMonthIndex = this.currentMonthIndex + 1;
      gsap.to(this.month_xs, {[nextMonthIndex]:target,duration:0.4});
    },
    handleMouseUp: function(event){
      this.drag_data.mouse_down = false;
      if (!this.drag_data.dragging){
        return;
      }
        //swipe right
        event.stopPropagation();
        if (this.diff > 0){
          if (this.diff <= this.container_w/2){
            //not far enough, go back
            this.animateCurrent(0);
          } else {
            //far enough, complete movement
            if (this.currentMonthIndex != 0){
              this.animateCurrent(this.container_w);
              this.currentMonthIndex = this.currentMonthIndex - 1;
            } else {
              this.animateCurrent(0);
            }
          }
        //swipe left
        } else {
          //not far enough, go back
          if (this.diff >= - this.container_w/2){
            if (this.currentMonthIndex < this.months.length){
              this.animateNext(this.container_w);
            }
          } else {
            //far enough,complete movement
            if (this.currentMonthIndex < this.months.length){
              this.animateNext(0);
              this.currentMonthIndex = this.currentMonthIndex + 1;
            }
        
          }
        }
        this.drag_data.dragging = false;
      },
    handleMonthGeometry: function(e){
      this.months_height = e.height;
      this.container_w = e.width;
      for (let i=this.currentMonthIndex + 1;i<this.months.length;++i){
        this.month_xs[i] = this.container_w;
      }
      this.month_name.x = 0.5*this.container_w; 
      this.month_name.y = e.name_height; 
      // this.months_height = e.height;
    },
    previousMonth: function(){
      if (this.currentMonthIndex != 0){
        this.animateCurrent(this.container_w);
        this.currentMonthIndex = this.currentMonthIndex - 1;
      }
    },
    nextMonth: function(){
      if (this.currentMonthIndex < this.months.length){
        this.animateNext(0);
        this.currentMonthIndex = this.currentMonthIndex + 1;
      }
    },
    onResize() {
      this.container_w = window.innerWidth;
    }
  },
  computed: {
    arrow_pos_l: function(){
      return {left:this.month_name.x - 122.5 +'px',top:this.month_name.y +'px'};
    },
    arrow_pos_r: function(){
      return {left:this.month_name.x + 90 +'px',top:this.month_name.y +'px'};
    },
    month_height: function(){
      //for one more day
      return this.months_height + 120;
    },
    month_pos: function (){
      return this.month_xs;
    },
    // currentYear: function () {
    //   if (this.$winter){
    //     if (this.currentMonthIndex <= 4){
    //       return this.$start_year + 1;
    //     } else {
    //       return this.$start_year;
    //     }
    //   } else {
    //     return this.$start_year;
    //   }
    // },
    diffX: function () {
      return this.cursorStartX - this.cursorCurrentX
    },
    swipingLeft: function () {
      return this.diffX >= 0
    },
    listMonthData: function () {
      var result = [];
      var currentDate = new Date();
      var currentMonth = currentDate.getMonth();
      var currentYear = currentDate.getFullYear();
      for (let i= 0;i<this.months.length;++i){
        var year = currentYear;
        if (currentMonth > 6 && this.months[i] <= 4)
          ++year;
        else if (currentMonth < 4 && this.months[i] > 6)
          --year;
        result.push({ year: year, month: this.months[i], xs: this.month_xs[i], zValue: 'z-' + (i-1)*10 });
      }
      return result
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
        if (this.$refs.month) {
          //console.log("this.$refs.month",this.$refs.month);
            //console.log("this.$refs.month.$el",this.$refs.month.$el);
            this.container_w = window.innerWidth; //this.$refs.month.$el.getBoundingClientRect().width;
            this.next_x = this.container_w;
        }
    });
    // console.log("In program",this.$program);
    emitter.on('mouseup_global',e => this.handleMouseUp(e));
    emitter.on('month_geometry',this.handleMonthGeometry);
    emitter.on('next_month',this.nextMonth);
    emitter.on('previous_month',this.previousMonth);
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize);
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
